export const ERROR_CODE = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  UNDEFINED: 'UNDEFINED',
  NOT_FOUND: 'NOT_FOUND',

  ACCOUNT_WAS_EXIST_IN_SYSTEM_WITH_ANOTHER_ROLE:
    'ACCOUNT_WAS_EXIST_IN_SYSTEM_WITH_ANOTHER_ROLE',
  ERROR_OCCURRED: 'ERROR_OCCURRED',
  NOT_INTERNET: 'NOT_INTERNET',
  UNAUTHORIZED: 'UNAUTHORIZED',
  EXIST_ITEM: 'EXIST_ITEM',
  ECONNABORTED: 'ECONNABORTED',
  EMAIL_WAS_EXIST: 'EMAIL_WAS_EXIST',
  ROLE_WAS_NOT_EXIST: 'ROLE_WAS_NOT_EXIST',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  PRODUCT_NOT_MATCH_WITH_CURRENT_SELLER:
    'PRODUCT_NOT_MATCH_WITH_CURRENT_SELLER',
  INVALID_REQUEST_BODY_DATA: 'INVALID_REQUEST_BODY_DATA',
  PASSWORD_INCORRECT: 'PASSWORD_INCORRECT',
  UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY',
  EMAIL_WAS_NOT_EXIST: 'EMAIL_WAS_NOT_EXIST',
  EMAIL_NOT_EXIST: 'EMAIL_NOT_EXIST',
  ORDER_LIMITED: 'ORDER_LIMITED',
};

export const ERROR_MESSAGE = {};
