import { takeEvery, call } from 'redux-saga/effects';
import userApi from 'api/userApi';
import * as actionTypes from './actionTypes';

function* handleGetSubscriptionList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(userApi.getSubscriptions, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_SUBSCRIPTION_LIST, handleGetSubscriptionList),
];
