import styled from 'styled-components';

export const TitleWrapper = styled.div`
  margin-bottom: 30px;
  .main-title {
    color: ${(props) => props.theme.palette.royalBlue700};
    font-family: ${(props) => props.theme.fonts.title};
    font-size: 30px;
  }
`;

export const MainTitleWrapper = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.primary};
  display: block;
  text-transform: uppercase;
  line-height: 1.2;
  padding: 8px 0 8px 20px;
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: '';
    width: 5px;
    background-color: ${(props) => props.theme.palette.primary};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;

export const SubTitleWrapper = styled.h3`
  margin-bottom: 30px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: ${(props) => props.theme.palette.primary};
  padding: 10px 15px;
`;
