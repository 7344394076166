import { takeEvery, call } from 'redux-saga/effects';
import metricApi from 'api/metric';
import categoryApi from 'api/category';
import * as actionTypes from './actionTypes';
// import productApi from 'api/productApi';
// import uploadImage from 'api/uploadImage';

function* handleGetMetricList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(metricApi.getList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleGetCategoryList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(categoryApi.getList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_METRIC_LIST, handleGetMetricList),
  takeEvery(actionTypes.GET_CATEGORY_LIST, handleGetCategoryList),
];
