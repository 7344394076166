export const SET_CURRENT_MENU = 'app/SET_CURRENT_MENU';
export const SET_CURRENT_MENU_SUCCESS = 'app/SET_CURRENT_MENU_SUCCESS';
export const SET_CURRENT_MENU_ERROR = 'app/SET_CURRENT_MENU_ERROR';

export const RESET_CURRENT_MENU = 'app/RESET_CURRENT_MENU';
export const RESET_CURRENT_MENU_SUCCESS = 'app/RESET_CURRENT_MENU_SUCCESS';
export const RESET_CURRENT_MENU_ERROR = 'app/RESET_CURRENT_MENU_ERROR';

export const CHANGE_CURRENT_LOCALE = 'app/CHANGE_CURRENT_LOCALE';

export const SET_LOADING = 'app/SET_LOADING';
