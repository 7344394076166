const theme = {};

theme.palette = {
  primary: '#384fa4',
  secondary: '#ff0066',
  background: '#f1f4f8',
  white: '#fff',
  black: '#070708',
  coral500: '#ff8387',
  jonquil: '#ffe530',
  turquoise200: '#d6fffa',
  turquoise500: '#4dccbd',
  robinsEggBlue: '#b2def1',
  violet: '#311495',
  royalBlue100: '#f5f7fa',
  royalBlue150: '#f1f4f8',
  royalBlue400: '#bbc8e1',
  royalBlue700: '#253c78',
  error: '#ff4d4f',
  dark600: '#636369',
  gold: '#fdca40',
  text: {
    default: '#070708',
    title: '#253c78',
  },
  bg: '#f7f9fc',
  border: '#e9e9e9',
};

theme.fonts = {
  en: {
    primary: `'Roboto', sans-serif`,
    title: `'Days One', sans-serif`,
  },
  ko: {
    primary: `'Spoqa Han Sans', sans-serif`,
    title: `'Spoqa Han Sans', sans-serif`,
  },
};

export default theme;
