import axiosClient from './axiosClient';

const metricApi = {
  getList: (params) => {
    return axiosClient.get(`/admin/metrics`, { params });
  },
  create: (data) => {
    return axiosClient.post('/admin/metrics', data);
  },
  delete: (id) => {
    return axiosClient.delete('/admin/metrics', id);
  },
};

export default metricApi;
