import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import app from 'app/reducers';
import auth from 'features/auth/reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app', 'auth'],
  // blacklist: ['router'],
};

export default function createRootReducer(history) {
  const rootReducer = combineReducers({
    app,
    auth,
    router: connectRouter(history),
  });

  // return rootReducer;
  return persistReducer(persistConfig, rootReducer);
}
