import { takeEvery, call, all, put } from 'redux-saga/effects';
import { doSetLoading } from 'app/actions';
import metricApi from 'api/metricApi';
import * as actionTypes from './actionTypes';

function* handleGetMetricList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(metricApi.getList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleDeleteMetricMulti({ payload }) {
  const { ids, callback } = payload;
  yield put(doSetLoading(true));
  try {
    const result = yield all(ids.map((id) => call(metricApi.delete, id)));
    callback({ error: false, data: result.length });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleCreateMetric({ payload }) {
  const { data, callback } = payload;
  yield put(doSetLoading(true));
  try {
    const result = yield call(metricApi.create, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleUpdateMetric({ payload }) {
  const { id, data, callback } = payload;
  yield put(doSetLoading(true));
  try {
    const result = yield call(metricApi.update, id, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

export default [
  takeEvery(actionTypes.GET_METRIC_LIST, handleGetMetricList),
  takeEvery(actionTypes.CREATE_METRIC_LIST, handleCreateMetric),
  takeEvery(actionTypes.DELETE_METRIC_MULTI, handleDeleteMetricMulti),
  takeEvery(actionTypes.UPDATE_METRIC, handleUpdateMetric),
];
