import { takeEvery, call, put } from 'redux-saga/effects';
import { doSetLoading } from 'app/actions';
import reportApi from 'api/reportApi';
import * as actionTypes from './actionTypes';

function* handleGetListReport({ payload }) {
  const { params, callback } = payload;

  yield put(doSetLoading(true));
  try {
    const result = yield call(reportApi.getReport, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

export default [takeEvery(actionTypes.GET_REPORT_LIST, handleGetListReport)];
