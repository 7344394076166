import axiosClient from './axiosClient';

const sellerApi = {
  getSellerList: (params) => {
    return axiosClient.get('/admin/users/sellers', { params });
  },
  blockUser: (id, status) => {
    return axiosClient.delete(`/admin/users/${id}/status/${status}`);
  },
};

export default sellerApi;
