export const TIMES = [
  {
    value: 'today',
    label: 'global.today',
  },
  {
    value: 'week',
    label: 'global.week',
  },
  {
    value: 'month',
    label: 'global.month',
  },
];

export const PAGINATION_PARAMS = {
  page: 1,
  limit: 10,
};

export const PRODUCT_DEMO_PAGINATION_PARAMS = {
  page: 1,
  limit: 5,
};

export const ORDER_STATUSES = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
};

export const CUSTOMER_TYPES = [
  {
    value: 'standard',
    label: 'global.standardCustomer',
  },
  {
    value: 'beloved',
    label: 'global.belovedCustomer',
  },
  {
    value: 'special',
    label: 'global.specialCustomer',
  },
];

export const LOCALE = {
  EN: 'en',
  KO: 'ko',
};

export const SUBSCRIPTION_PLAN = {
  FREE: 'Free',
  PLAN_A: 'Plan A',
  PLAN_B: 'Plan B',
  PLAN_C: 'Plan C',
  REQUEST: 'Request',
};

export const SUBSCRIPTION_STATUSES = [
  {
    label: 'global.approve',
    value: 'approve',
  },
  {
    label: 'global.pause',
    value: 'pause',
  },
  {
    label: 'global.cancel',
    value: 'cancel',
  },
  {
    label: 'global.revoke',
    value: 'revoke',
  },
];

export const UNITS = ['package', 'box', 'sack', 'bottle'];
