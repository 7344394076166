import { ERROR_CODE } from '../constants/errors';

function convertDataError(error) {
  const { code, message } = error;

  if (message && message.search('Cannot read property') >= 0) {
    return {
      code: ERROR_CODE.UNDEFINED,
      message: ERROR_CODE.UNDEFINED,
    };
  }

  if (message && message.search('Network Error') >= 0) {
    return {
      code: ERROR_CODE.NETWORK_ERROR,
      message: ERROR_CODE.NETWORK_ERROR,
    };
  }

  if (code === ERROR_CODE.ECONNABORTED) {
    return {
      code: ERROR_CODE.ECONNABORTED,
      message: ERROR_CODE.ECONNABORTED,
    };
  }

  return {
    code,
    message,
  };
}

export function handleErrorMessage(err) {
  const { response } = err;

  if (response) {
    return convertDataError({
      ...response.data,
    });
  }

  return convertDataError(err);
}
