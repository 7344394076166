import { takeEvery, call } from 'redux-saga/effects';
import sellerApi from 'api/sellerApi';
import * as actionTypes from './actionTypes';

function* handleGetListSeller({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(sellerApi.getSellerList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleBlockSeller({ payload }) {
  const { id, status, callback } = payload;

  try {
    const result = yield call(sellerApi.blockUser, id, status);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_SELLER_LIST, handleGetListSeller),
  takeEvery(actionTypes.BLOCK_SELLER, handleBlockSeller),
];
