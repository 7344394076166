import * as actionTypes from './actionTypes';

const initialState = {
  userInfo: null,
  isOAuth: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload.data || { ...state.userInfo },
        isOAuth: true,
      };
    }

    case actionTypes.LOGOUT: {
      return {
        ...state,
        userInfo: null,
        isOAuth: false,
      };
    }

    default: {
      return state;
    }
  }
};
