import { all } from 'redux-saga/effects';

import authSagas from 'features/auth/sagas';
import subscriptionSagas from 'features/subscription/sagas';
import reportSagas from 'features/reporting/sagas';
import sellerSagas from 'features/seller/sagas';
import buyerSagas from 'features/buyer/sagas';
import productSagas from 'features/product/sagas';
import orderSagas from 'features/order/sagas';
import settingSagas from 'features/setting/sagas';
import demoSagas from 'features/demoContent/sagas';
import metricSagas from 'features/metric/sagas';
import unitSagas from 'features/unit/sagas';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...subscriptionSagas,
    ...reportSagas,
    ...sellerSagas,
    ...buyerSagas,
    ...productSagas,
    ...orderSagas,
    ...settingSagas,
    ...demoSagas,
    ...metricSagas,
    ...unitSagas,
  ]);
}
