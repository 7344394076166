import koKR from 'antd/es/locale/ko_KR';
import koMessage from '../locales/ko.json';

export default {
  message: {
    ...koMessage,
  },
  antd: koKR,
  locale: 'ko',
};
