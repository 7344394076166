import * as actionTypes from './actionTypes';
import { LOCALE } from '../constants/global';

const initialState = {
  currentLocale: LOCALE.EN,
  defaultSelectedKeys: ['orders'],
  defaultOpenKeys: [],
  isLoading: false,
};

const appReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_MENU: {
      const { selectedKeys, openKeys } = action.payload;

      return {
        ...state,
        defaultSelectedKeys: selectedKeys,
        defaultOpenKeys: openKeys,
      };
    }

    case actionTypes.RESET_CURRENT_MENU: {
      return { ...initialState, currentLocale: state.currentLocale };
    }

    case actionTypes.CHANGE_CURRENT_LOCALE: {
      return {
        ...state,
        currentLocale: action.payload.currentLocale,
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
