import axiosClient from './axiosClient';

const buyerApi = {
  getList: (params) => {
    return axiosClient.get('/admin/users/buyers', { params });
  },
  block: (id, status) => {
    return axiosClient.delete(`/admin/users/${id}/status/${status}`);
  },
};

export default buyerApi;
