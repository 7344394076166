import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TitleWrapper, MainTitleWrapper, SubTitleWrapper } from './style';

const Title = ({ id }) => {
  return (
    <TitleWrapper>
      <h2 className="main-title">
        <FormattedMessage id={id} />
      </h2>
    </TitleWrapper>
  );
};

Title.MainTitle = function MainTitle({ id = 'mainTitle.default' }) {
  return (
    <MainTitleWrapper>
      <FormattedMessage
        id={id}
        defaultMessage="ORDER EASY"
        // values={{ name }}
      />
    </MainTitleWrapper>
  );
};

Title.SubTitle = function SubTitle({ id = 'subTitle.default' }) {
  return (
    <SubTitleWrapper>
      <FormattedMessage
        id={id}
        defaultMessage="ORDER EASY"
        // values={{ name }}
      />
    </SubTitleWrapper>
  );
};

Title.propTypes = {
  id: PropTypes.string.isRequired,
};
Title.MainTitle.propTypes = {
  id: PropTypes.string,
};
Title.SubTitle.propTypes = {
  id: PropTypes.string,
};

export default Title;
