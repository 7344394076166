import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import productApi from '../../api/productApi';
import uploadImage from '../../api/uploadImage';

function* handleGetProductList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(productApi.getProductList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleDeleteProduct({ payload }) {
  const { id, callback } = payload;

  try {
    const result = yield call(productApi.deleteProductList, id);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handlePostProduct({ payload }) {
  const { data, callback } = payload;

  try {
    const { image, ...restData } = data;
    let newData = { ...restData };
    console.log('img : ', image);
    if (image) {
      const imageResult = yield call(uploadImage, { file: image });
      newData = { ...newData, images: imageResult.data.url };
      console.log('new data2 : ', newData);
    }
    const result = yield call(productApi.postProduct, newData);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_PRODUCT_LIST, handleGetProductList),
  takeEvery(actionTypes.DELETE_PRODUCT, handleDeleteProduct),
  takeEvery(actionTypes.POSTPRODUCT, handlePostProduct),
];
