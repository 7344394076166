import axiosClient from './axiosClient';

const settingApi = {
  sendFeedback: (data) => {
    return axiosClient.post('/feedbacks', data);
  },
  updateProfile: (data) => {
    return axiosClient.put('/users', data);
  },
};

export default settingApi;
