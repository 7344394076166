import { injectIntl } from 'react-intl';

// eslint-disable-next-line import/no-mutable-exports
export let cIntl = null;

const IntlGlobalProvider = ({ intl, children }) => {
  cIntl = intl;

  return children;
};

export default injectIntl(IntlGlobalProvider);
