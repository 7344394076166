/* eslint-disable no-template-curly-in-string */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import AppLocale from '../../assets/translations';
import themes from '../../assets/themes';

import IntlGlobalProvider from '../../contexts/IntlGlobalProvider';
import Loading from '../../components/Loading';
import { LOCALE } from '../../constants/global';
import GlobalStyles from '../../assets/styles/globalStyle';
import Routes from '../../router';
import { handleCheckAuth } from '../../features/auth/actions';

const validateMessages = {
  [LOCALE.EN]: {
    required: '${label} is required',
  },
  [LOCALE.KO]: {
    required: '${label} 는/은 필수 정보입니다.',
  },
};

function App() {
  const dispatch = useDispatch();
  const currentLocale = useSelector((state) => state.app.currentLocale);
  // console.log('AppLocale: ', AppLocale, themes);

  useEffect(() => {
    dispatch(handleCheckAuth());
  }, [dispatch]);

  return (
    <ConfigProvider
      form={{
        validateMessages: validateMessages[currentLocale],
      }}
      locale={AppLocale[currentLocale].antd}
      componentSize="large"
    >
      <IntlProvider
        locale={AppLocale[currentLocale].locale}
        messages={AppLocale[currentLocale].message}
      >
        <IntlGlobalProvider>
          <ThemeProvider
            theme={{ ...themes, fonts: themes.fonts[currentLocale] }}
          >
            <GlobalStyles />
            <Routes />
            <Loading />
          </ThemeProvider>
        </IntlGlobalProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
