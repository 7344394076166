import { takeEvery, call } from 'redux-saga/effects';
import buyerApi from 'api/buyerApi';
import * as actionTypes from './actionTypes';

function* handleGetBuyerList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(buyerApi.getList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleBlockBuyer({ payload }) {
  const { id, status, callback } = payload;

  try {
    const result = yield call(buyerApi.block, id, status);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.GET_BUYER_LIST, handleGetBuyerList),
  takeEvery(actionTypes.BLOCK_BUYER, handleBlockBuyer),
];
