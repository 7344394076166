import axiosClient from './axiosClient';

const unitApi = {
  getList: (params) => {
    return axiosClient.get(`/admin/units`, { params });
  },
  create: (data) => {
    return axiosClient.post('/admin/units', data);
  },
  delete: (id) => {
    return axiosClient.delete(`/admin/units/${id}`);
  },
  update: (id, data) => {
    return axiosClient.put(`/admin/units/${id}`, data);
  },
};

export default unitApi;
