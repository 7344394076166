import axiosClient from './axiosClient';

const productApi = {
  getProductList: (params) => {
    return axiosClient.get('/admin/products', { params });
  },
  postProduct: (data) => {
    return axiosClient.post('/admin/products', data);
  },
  deleteProductList: (id) => {
    return axiosClient.delete(`/admin/products/${id}`);
  },
};

export default productApi;
