import { takeEvery, call } from 'redux-saga/effects';
import orderApi from 'api/orderApi';
import * as actionTypes from './actionTypes';

function* handleGetOrderList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(orderApi.getList, params);

    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

export default [takeEvery(actionTypes.GET_ORDER_LIST, handleGetOrderList)];
