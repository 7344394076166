//https://flatuicolors.com/palette/defo
export const COLOR_INFO = '#3498db';
export const COLOR_SUCCESS = '#2ecc71';
export const COLOR_WARNING = '#f1c40f';
export const COLOR_ERROR = '#e74c3c';

export const COLOR_ACTIVE = '#2ecc71';
export const COLOR_INACTIVE = '#e74c3c';

export const COLOR_PENDING_STATUS = '#f1c40f';
export const COLOR_IN_PROCESS_STATUS = '#3498db';
export const COLOR_SUCCESS_STATUS = '#2ecc71';
export const COLOR_FAILED_STATUS = '#e74c3c';
