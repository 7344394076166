import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IMAGES from '../../constants/images';

const WrapperLogo = styled.div`
  height: 70px;
  background: #1f2431;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  overflow: hidden;
  border-radius: 0;

  img {
    max-height: 100%;
    width: auto;
  }
`;

const Logo = ({ collapsed = false }) => {
  return (
    <WrapperLogo className="logoWrapper">
      <img
        src={collapsed ? IMAGES.circleLogo : IMAGES.rectangleLogo}
        alt="logo"
      />
    </WrapperLogo>
  );
};

Logo.propTypes = {
  collapsed: PropTypes.bool,
};

export default React.memo(Logo);
