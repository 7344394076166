import * as actionTypes from './actionTypes';

const doSetCurrentMenu = (payload) => ({
  type: actionTypes.SET_CURRENT_MENU,
  payload,
});

const doResetCurrentMenu = () => ({
  type: actionTypes.RESET_CURRENT_MENU,
});

const doChangeCurrentLocale = (currentLocale) => ({
  type: actionTypes.CHANGE_CURRENT_LOCALE,
  payload: {
    currentLocale,
  },
});

const doSetLoading = (isLoading) => ({
  type: actionTypes.SET_LOADING,
  payload: {
    isLoading,
  },
});

export {
  doSetCurrentMenu,
  doResetCurrentMenu,
  doChangeCurrentLocale,
  doSetLoading,
};
