import { takeEvery, call, all, put } from 'redux-saga/effects';
import { doSetLoading } from 'app/actions';
import unitApi from 'api/unitApi';
import * as actionTypes from './actionTypes';

function* handleGetUnitList({ payload }) {
  const { params, callback } = payload;

  try {
    const result = yield call(unitApi.getList, params);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
}

function* handleDeleteUnitMulti({ payload }) {
  const { ids, callback } = payload;
  yield put(doSetLoading(true));
  try {
    const result = yield all(ids.map((id) => call(unitApi.delete, id)));
    callback({ error: false, data: result.length });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleCreateUnit({ payload }) {
  const { data, callback } = payload;
  yield put(doSetLoading(true));
  try {
    const result = yield call(unitApi.create, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

function* handleUpdateUnit({ payload }) {
  const { id, data, callback } = payload;
  yield put(doSetLoading(true));
  try {
    const result = yield call(unitApi.update, id, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true });
  }
  yield put(doSetLoading(false));
}

export default [
  takeEvery(actionTypes.GET_UNIT_LIST, handleGetUnitList),
  takeEvery(actionTypes.CREATE_UNIT, handleCreateUnit),
  takeEvery(actionTypes.DELETE_UNIT_MULTI, handleDeleteUnitMulti),
  takeEvery(actionTypes.UPDATE_UNIT, handleUpdateUnit),
];
