import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';

import settingApi from '../../api/settingApi';

function* handleSendContactMessage({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(settingApi.sendFeedback, data);

    callback({ error: false });
  } catch (error) {
    callback({ error: true });
  }
}

export default [
  takeEvery(actionTypes.SEND_CONTACT_MESSAGE, handleSendContactMessage),
];
